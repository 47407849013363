import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useBookingStore from "../../store/bookings";
import { useEffect, useState } from "react";
import Vars from "../../_config/vars";

const RefundDialog = () => {
  const {
    selectedBooking,
    openRefundDialog,
    setOpenRefundDialog,
    refundBooking,
    refundData,
    setRefundData,
    setSnackBar,
    setBookings,
    setBookingsPending,
    searchBooking,
    setSelectedBooking,
    getPurchaseProvider
  } = useBookingStore((state) => ({
    selectedBooking: state.selectedBooking,
    openRefundDialog: state.openRefundDialog,
    setOpenRefundDialog: state.setOpenRefundDialog,
    refundBooking: state.refundBooking,
    refundData: state.refundData,
    setRefundData: state.setRefundData,
    setSnackBar: state.setSnackBar,
    setBookings: state.setBookings,
    setBookingsPending: state.setBookingsPending,
    searchBooking: state.searchBooking,
    setSelectedBooking: state.setSelectedBooking,
    getPurchaseProvider: state.getPurchaseProvider
  }));

  const [otherReasonIsSelected, setOtherReasonIsSelected] = useState(false);

  console.log("refundData", refundData.type);

  const { cart } = selectedBooking;
  const { items } = cart;

  const amountFees = items.reduce((prev: number, next: any) => {
    return prev + (next.pricing.total_price - next.pricing.official_price) * next.quantity;
  }, 0);
 

  const handleClose = () => {
    setOpenRefundDialog(false);
    // reset the refundData state
    setRefundData({
      ...refundData,
      reason: "",
      type: "",
      amount: {
        currency: "",
        amount: 0
      }
    });
  }

  const handleChangeReason = (event: any) => {

    if (event.target.value !== "Other" && otherReasonIsSelected) {
      setOtherReasonIsSelected(false);
    }

    setRefundData({
      ...refundData,
      reason: event.target.value
    });
  };

  const handleChangeType = (event: any) => {
    if (event.target.value === "Total") {

      setRefundData({
        ...refundData,
        type: event.target.value,
        amount: {
          value:  parseFloat(selectedBooking.payment.amount.value).toFixed(2),
          currency: selectedBooking.payment.amount.currency,
        }
      });
    } else {
      setRefundData({
        ...refundData,
        type: event.target.value,
        amount: {
          value: parseFloat(amountFees).toFixed(2),
          currency: selectedBooking.payment.amount.currency,
        }
      });
    }
  };

  const handleRefund = async () => {
    const done = await refundBooking();

    if (done) {
      setSnackBar({
        open: true,
        message: "Refund done successfully",
        type: "success",
      });

      // await updateLocalStorage();
      await handleSelectedBooking();      
      
      setTimeout(() => {
        handleClose();
        setSnackBar({
          open: false,
          message: "Refund done successfully",
          type: "success",
        });
      }
        , 2000);
    } else {
      setSnackBar({
        open: true,
        message: "Refund error",
        severity: "error",
      });

      setTimeout(() => {
        handleClose();
        setSnackBar({
          open: false,
          message: "Refund error",
          severity: "error",
        });
      }
        , 2000);
    }
  }

  const handleChangeOtherReason = (event: any) => {
    setRefundData({
      ...refundData,
      reason: event.target.value,
    });
  };

  // const updateLocalStorage = async () => {
  //   await setBookings();
  //   await setBookingsPending();
  //   await searchBooking("pendings");
  // }

  const handleSelectedBooking = async () => {
    const url = window.location.pathname;
    const id = url.substring(url.lastIndexOf('/') + 1);
    console.log("id edit dialog", id);
    await setSelectedBooking(id);
    await getPurchaseProvider();
    console.log("selectedBooking", selectedBooking);
  }

  const open = openRefundDialog;

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Refund
      </DialogTitle>
      <DialogContent>
        <TextField
          select
          margin="dense"
          label="Refund Reason"
          fullWidth
          variant="standard"
          value={refundData.reason}
          onChange={(e) => handleChangeReason(e)}
        >
          <MenuItem value={"Peticion del cliente: order no gestionada"}>
            Petición cliente: order no gestionada
          </MenuItem>
          <MenuItem value={"Peticion del cliente: order gestionada errónea"}>
            Petición cliente: order gestionada errónea
          </MenuItem>
          <MenuItem value={"Peticion del cliente: venue closed"}>
            Petición cliente: venue closed
          </MenuItem>
          <MenuItem value={"Unavailability time: fuera de horario CS"}>
            Unavailability time: fuera de horario CS
          </MenuItem>
          <MenuItem
            value={"Unavailability time: bot no recogió disponibilidad"}
          >
            Unavailability time: bot no recogió disponibilidad
          </MenuItem>
          <MenuItem value={"Unavailability time: to review falsa"}>
            Unavailability time: to review falsa
          </MenuItem>
          <MenuItem value={"Mail del cliente bloqueado"}>
            Error bot: block email
          </MenuItem>
          <MenuItem value={"Fraude"}>Fraude</MenuItem>
          <MenuItem onClick={() => setOtherReasonIsSelected(true)} value={"Other"}>Other</MenuItem>
        </TextField>
        {otherReasonIsSelected && (
          <TextField
            fullWidth
            variant="standard"
            value={refundData.reason}
            margin="dense"
            label="Other reason"  
            onChange={(e) => handleChangeOtherReason(e)}
          />
        )}
        <FormControl sx={{ mt: 2 }}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Amount
          </FormLabel>
          <RadioGroup
            row
            value={refundData.type}
            onChange={(e) => handleChangeType(e)}
          >
            <FormControlLabel
              value={"Total"}
              control={<Radio />}
              name="amount"
              label={`Total (${cart.total_price}€)`}
            />
            <FormControlLabel
              value={"Partial"}
              control={<Radio />}
              name="amount"
              label={`Partial (${amountFees}€)`}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleRefund} autoFocus
        disabled={refundData.reason === ""}
        >
          Refund
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefundDialog;
import create from "zustand";
import { User } from "../types/users.interface";

interface State {
  openRemoveUserDialog: boolean;
  setOpenRemoveUserDialog: (openRemoveUserDialog: boolean) => void;
  selectedUser: User | null;
  setSelectedUser: (user: User) => void;
  openCreateCutomerServiceDialog: boolean;
  setOpenCreateCutomerServiceDialog: (
    openCreateCutomerServiceDialog: boolean
  ) => void;
  openDeleteCustomerServiceDialog: boolean;
  setOpenDeleteCustomerServiceDialog: (
    openDeleteCustomerServiceDialog: boolean
  ) => void;
}

const useDialogsStore = create<State>((set) => ({
  openRemoveUserDialog: false,
  setOpenRemoveUserDialog: (openRemoveUserDialog) =>
    set({ openRemoveUserDialog }),
  selectedUser: null,
  setSelectedUser: (selectedUser) => set({ selectedUser }),
  openCreateCutomerServiceDialog: false,
  setOpenCreateCutomerServiceDialog: (openCreateCutomerServiceDialog) =>
    set({ openCreateCutomerServiceDialog }),
  openDeleteCustomerServiceDialog: false,
  setOpenDeleteCustomerServiceDialog: (openDeleteCustomerServiceDialog) =>
    set({ openDeleteCustomerServiceDialog }),
}));

export default useDialogsStore;

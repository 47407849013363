import { useState } from "react";
import { Config } from "../../../../config";
import axios from "axios";
import to from "await-to-js";

interface CreateCustomerServiceParams {
  userId: string;
  team: string;
}

export const useCustomerService = () => {
  const [loading, setLoading] = useState(false);
  const API_URL = Config.REACT_APP_API_TICKETDOOR;

  const createCustomerService = async ({
    userId,
    team,
  }: CreateCustomerServiceParams) => {
    try {
      setLoading(true);

      const body = {
        userId,
        team,
      };

      const [error, response] = await to(
        axios.post(`${API_URL}/customer-service`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("ticketdoorToken")}`,
          },
        })
      );

      if (error) {
        console.error("Error creating customer service:", error);
        setLoading(false);
        return false;
      }

      setLoading(false);
      return true;
    } catch (error) {
      console.error("Error creating customer service:", error);
      setLoading(false);
      return false;
    }
  };

  const deleteCustomerService = async (userId: string) => {
    try {
      setLoading(true);

      const [error, response] = await to(
        axios.delete(`${API_URL}/customer-service/${userId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("ticketdoorToken")}`,
          },
        })
      );

      if (error) {
        console.error("Error deleting customer service:", error);
        setLoading(false);
        return false;
      }

      setLoading(false);
      return true;
    } catch (error) {
      console.error("Error deleting customer service:", error);
      setLoading(false);
      return false;
    }
  };

  return { createCustomerService, deleteCustomerService, loading };
};

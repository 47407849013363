const CustomersVars = {
  API_URL: "http://localhost:4500",
  booking: {
    editable: [
      "status",
      "purchaseMail",
      "trackingNumber",
      "timeRange",
      "ticketsDate",
    ],
    sorted: [
      "activityName",
      "trackingNumber",
      //"bookingHandlerStatus",
      "statusPurchaseProvider",
      "errorTag",
      "attempts",
      "firstName",
      "purchaseEmail",
      "phone",
      "customer_phone",
      "visitDate",
      "timeRange",
      "purchaseProviderLogs",
      "totalPrice",
      "bookingId",
      "paymentId",
      "paymentProvider",
      "card",
      "sessionId",
    ],
  },
  customer: {
    editable: ["firstName", "lastName", "email"],
    sorted: ["firstName", "lastName", "email", "phoneNumber", "country"],
  },
  status: ["Processing", "Error", "Success"],
  "BCN - SAFA - ET": {
    hours: [
      "09:00",
      "09:15",
      "09:30",
      "09:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "13:00",
      "13:15",
      "13:30",
      "13:45",
      "14:00",
      "14:15",
      "14:30",
      "14:45",
      "15:00",
      "15:15",
      "15:30",
      "15:45",
      "16:00",
      "16:15",
      "16:30",
      "16:45",
      "17:00",
      "17:15",
      "17:30",
      "17:45",
      "18:00",
      "18:15",
      "18:30",
      "18:45",
      "19:00",
      "19:15",
    ],
  },
  "BCN - PAGU - ET": {
    hours: [
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
    ],
  },
  "ATH - ACRO - ET": {
    hours: [
      "08:00-09:00",
      "09:00-10:00",
      "10:00-11:00",
      "11:00-12:00",
      "12:00-13:00",
      "13:00-14:00",
      "14:00-15:00",
      "15:00-16:00",
      "16:00-17:00",
      "17:00-18:00",
      "17:30-18:00",
      "18:00-19:00",
      "19:00-20:00",
    ],
    fees: {
      Adults: 10,
      Infant: 0,
      Child: 0,
    },
    officialPrice: {
      Adults: 20,
      Infant: 0,
      Child: 0,
    },
    emailPurchase: "orders@acropolis-tickets.org",
  },
  "PAR - VEPA - ET": {
    hours: [
      "9:00",
      "9:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
    ],
    fees: {
      Adults: 10,
      Infant: 0,
      Child: 0,
    },
    officialPrice: {
      Adults: 20,
      Infant: 0,
      Child: 0,
    },
    emailPurchase: "orders@versaillespalace.paristickets.org",
  },
  "ROM - COLO - ET": {
    hours: [
      "8:30",
      "8:45",
      "9:00",
      "9:15",
      "9:30",
      "9:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "13:00",
      "13:15",
      "13:30",
      "13:45",
      "14:00",
      "14:15",
      "14:30",
      "14:45",
      "15:00",
      "15:15",
      "15:30",
      "15:45",
      "16:00",
      "16:15",
      "16:30",
      "16:45",
      "17:00",
      "17:15",
      "17:30",
      "17:45",
      "18:00",
    ],
    fees: {
      Adults: 12,
      Reduced: 12,
    },
    officialPrice: {
      Adults: 18,
      Reduced: 4,
    },
    emailPurchase: "mariaperez63521@gmail.com",
  },
  "PAR - LOMU - ET": {
    hours: [
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
    ],
    fees: {
      Adults: 10,
      Infant: 0,
      Child: 0,
    },
    officialPrice: {
      Adults: 22,
      Infant: 0,
      Child: 0,
    },
  },
  "AMS - RIJK - ET": {
    hours: [
      "09:00",
      "09:15",
      "09:30",
      "09:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "13:00",
      "13:15",
      "13:30",
      "13:45",
      "14:00",
      "14:15",
      "14:30",
      "14:45",
      "15:00",
      "15:15",
      "15:30",
    ],
  },
  "MIL - DUMI - ET": {
    hours: [
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
    ],
  },
  "AMS - VGMU - ET": {
    hours: [
      "09:00",
      "09:15",
      "09:30",
      "09:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "13:00",
      "13:15",
      "13:30",
      "13:45",
      "14:00",
      "14:15",
      "14:30",
      "14:45",
      "15:00",
      "15:15",
      "15:30",
      "15:45",
      "16:00",
      "16:15",
      "16:30",
    ],
  },
};

export default CustomersVars;

import { Button, Grid } from "@mui/material";
import useUserStore from "./store/users";
import { useEffect, useState } from "react";
import { User } from "./types/users.interface";
import UserForm from "./components/UserForm";
import { useGetUser } from "./hooks/useGetUser";
import { Toast } from "../../../components/Toast";
import CreateCustomerServiceDialog from "./components/dialogs/CreateCustomerServiceDialog";
import DeleteCustomerServiceDialog from "./components/dialogs/DeleteCustomerServiceDialog";
import useDialogsStore from "./store/dialogs";

const UpdateUser = () => {
  const [user, setUser] = useState<User | null>();
  const { usersList } = useUserStore();
  const { getUser } = useGetUser();
  const {
    openCreateCutomerServiceDialog,
    setOpenCreateCutomerServiceDialog,
    openDeleteCustomerServiceDialog,
    setOpenDeleteCustomerServiceDialog,
  } = useDialogsStore();

  const fetchUser = async () => {
    if (usersList.length === 0) {
      const userId = window.location.pathname.split("/").pop();
      if (userId) {
        const user = await getUser(userId);
        setUser(user);
      }
    } else {
      const userId = window.location.pathname.split("/").pop();
      const user = usersList.find((user) => user.id === userId);
      setUser(user);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [usersList]);

  const handleCreateCustomerServiceClick = () => {
    setOpenCreateCutomerServiceDialog(true);
  };

  const handleDeleteCustomerServiceClick = () => {
    setOpenDeleteCustomerServiceDialog(true);
  };

  const handleCustomerServiceSuccess = async () => {
    await fetchUser();
  };

  return (
    <>
      <Grid padding={"2rem"} marginBottom={"5rem"} container>
        <Grid item xs={12} display={"flex"} gap={"2rem"}>
          <h1>Update User</h1>
          {user && !user.customer_service && (
            <Button
              variant="contained"
              onClick={handleCreateCustomerServiceClick}
            >
              Create CS
            </Button>
          )}
          {user && user.customer_service && (
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteCustomerServiceClick}
            >
              Delete CS
            </Button>
          )}
        </Grid>
        {user && <UserForm user={user} />}
      </Grid>

      <CreateCustomerServiceDialog
        open={openCreateCutomerServiceDialog}
        onClose={() => setOpenCreateCutomerServiceDialog(false)}
        user={user ?? null}
        onSuccess={handleCustomerServiceSuccess}
      />
      <DeleteCustomerServiceDialog
        open={openDeleteCustomerServiceDialog}
        onClose={() => setOpenDeleteCustomerServiceDialog(false)}
        user={user ?? null}
        onSuccess={handleCustomerServiceSuccess}
      />
      <Toast />
    </>
  );
};

export default UpdateUser;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { User } from "../../types/users.interface";
import { LoadingCircle } from "../../../../customers/components/LoadingCircle";
import { useState } from "react";
import { useCustomerService } from "../../hooks/useCustomerService";

interface CreateCustomerServiceDialogProps {
  open: boolean;
  onClose: () => void;
  user: User | null;
  onSuccess?: () => void;
}

const CreateCustomerServiceDialog = ({
  open,
  onClose,
  user,
  onSuccess,
}: CreateCustomerServiceDialogProps) => {
  const [team, setTeam] = useState("Ticketdoor");

  const { createCustomerService, loading } = useCustomerService();

  const handleCreateCustomerService = async () => {
    if (user?.id) {
      const success = await createCustomerService({
        userId: user.id,
        team: team,
      });
      if (success && onSuccess) {
        onSuccess();
      }
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Create new Customer Service
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to create the user {user?.email} as a Customer
          Service? This action cannot be reversed at this momment.
        </DialogContentText>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="team-select-label">Team</InputLabel>
          <Select
            labelId="team-select-label"
            id="team-select"
            value={team}
            label="Team"
            onChange={(e) => setTeam(e.target.value)}
          >
            <MenuItem value="Ticketdoor">Ticketdoor</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleCreateCustomerService}
          variant="contained"
          color="info"
          disabled={loading}
        >
          {loading ? "Creating..." : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCustomerServiceDialog;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { User } from "../../types/users.interface";
import { useCustomerService } from "../../hooks/useCustomerService";

interface DeleteCustomerServiceDialogProps {
  open: boolean;
  onClose: () => void;
  user: User | null;
  onSuccess?: () => void;
}

const DeleteCustomerServiceDialog = ({
  open,
  onClose,
  user,
  onSuccess,
}: DeleteCustomerServiceDialogProps) => {
  const { deleteCustomerService, loading } = useCustomerService();

  const handleDeleteCustomerService = async () => {
    if (user?.customer_service?.id) {
      const success = await deleteCustomerService(user.customer_service.id);
      if (success && onSuccess) {
        onSuccess();
      }
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Delete Customer Service</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove {user?.email} from Customer Service?
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleDeleteCustomerService}
          variant="contained"
          color="error"
          disabled={loading}
        >
          {loading ? "Deleting..." : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCustomerServiceDialog;
